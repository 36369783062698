<template>
  <div>
    <div class="workplatform-title m-b-10">作业站点列表 ({{actionDate}})</div>
    <Row
      :gutter="8"
      class="m-b-5"
    >
      <i-col span="3">
        <Select
          v-model="query.assetId"
          size="small"
          clearable
          placeholder="线路"
          @on-change="handleChangeAsset"
        >
          <Option
            v-for="item in assetArray"
            :key="'asset_'+item.assetId"
            :value="item.assetId"
          >{{item.assetName}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.stationId"
          size="small"
          clearable
          placeholder="站点"
        >
          <Option
            v-for="item in stationArray"
            :key="'station_'+item.domainId"
            :value="item.domainId"
          >{{item.domainName}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="position"
          size="small"
          clearable
          placeholder="含/不含轨行区"
        >
          <Option
            v-for="(item,index) in arreaArray"
            :key="'area_'+index"
            :value="item.value"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.supplierId"
          size="small"
          clearable
          placeholder="供应商"
        >
          <Option
            v-for="item in companyArray"
            :key="'supplier_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="2">
        <Select
          v-model="query.status"
          size="small"
          clearable
          placeholder="状态"
        >
          <Option
            v-for="item in statusArray"
            :key="'status_'+item.value"
            :value="item.value"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="2">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="handleSearch"
        >搜索</Button>
      </i-col>
    </Row>
    <Row class="m-b-5">
      <i-col span="24">
        <Button
          type="success"
          size="small"
          class="m-r-5"
          :disabled="selectedTaskIds.length===0||![0].includes(selectedTaskStatus)"
          @click="handleApply"
        >发起申请</Button>
        <Button
          type="error"
          size="small"
          class="m-r-5"
          :disabled="selectedTaskIds.length===0||![1].includes(selectedTaskStatus)"
          @click="handleCancelApply"
        >取消申请</Button>
        <Button
          type="success"
          size="small"
          class="m-r-5"
          :disabled="selectedTaskIds.length===0||![1].includes(selectedTaskStatus)"
          @click="handleConfirmApply"
        >确认申请</Button>
        <Button
          type="error"
          size="small"
          class="m-r-5"
          :disabled="selectedTaskIds.length===0||![2].includes(selectedTaskStatus)"
          @click="handleDelayConfirm"
        >延期</Button>
        <Button
          type="primary"
          size="small"
          class="m-r-5 m-l-10"
          :disabled="selectedTaskIds.length===0||![2].includes(selectedTaskStatus)"
          @click="handleQuickDispatch"
        >一键派单</Button>
        <Button
          type="success"
          size="small"
          class="m-r-5"
          :disabled="selectedStationIds.length===0"
          @click="handleDownload"
        >下载作业计划</Button>
      </i-col>
    </Row>
    <Table
      size="small"
      stripe
      :data="tableData"
      :columns="tableColumns"
      @on-selection-change="selectionChange"
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        show-sizer
        :page-size-opts="pagesizeSetting"
        @on-page-size-change='changePageSize'
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { companyMixin } from '@/assets/mixins/company'
import { commonMixins } from '@/assets/mixins/common'
import { getStationPage, getAssetDomainList, apply, confirm, assignTaskitem } from '@/api/msp/workday'
import { downloadFileRequest } from '@/utils/download'
export default {
  mixins: [sysMixins, companyMixin, commonMixins],
  data () {
    return {
      type: 3, // 获取供应商标识
      assetArray: [],
      stationArray: [],
      arreaArray: [
        { value: 0, name: '含' },
        { value: 1, name: '不含' }
      ],
      // supplierArray: [],
      statusArray: [
        { value: 0, name: '计划中' },
        { value: 1, name: '申请中' },
        { value: 2, name: '已确认' },
        { value: -1, name: '删除' }
      ],
      pagesizeSetting: [15, 50, 100, 200, 500],
      tempSelectedTaskItem: [],
      selectedTaskIds: [],
      selectedTaskStatus: null,
      selectedStationIds: [],
      query: {
        startDate: '',
        endDate: '',
        assetId: null,
        stationId: null,
        notContainDT_GXQ: null,
        supplierId: null,
        status: null,
        pageNumber: 1,
        pageSize: 15
      },
      position: null,
      total: 0,
      tableData: [],
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '线路', align: 'center', key: 'assetName' },
        {
          title: '作业类型',
          align: 'center',
          render: (h, params) => {
            if (params.row.taskitemTypeList && params.row.taskitemTypeList.length) {
              const html = []
              params.row.taskitemTypeList.forEach(item => {
                html.push(h('p', item.taskitemTypeName))
              })
              return h('div', html)
            } else {
              return null
            }
          }
        },
        { title: '站点', align: 'center', key: 'stationName' },
        {
          title: '作业区域',
          align: 'center',
          render: (h, params) => {
            if (params.row.positionList && params.row.positionList.length) {
              const html = []
              params.row.positionList.forEach(item => {
                html.push(h('p', `${item.positionName} (${item.taskitemCount})`))
              })
              return h('div', html)
            } else {
              return null
            }
          }
        },
        { title: '作业数合计', align: 'center', key: 'totalCount' },
        {
          title: '预计作业起始时间',
          align: 'center',
          width: 140,
          render: (h, params) => {
            return h('span', `${params.row.startTime ? params.row.startTime.substring(0, 5) : ''} - ${params.row.endTime ? params.row.endTime.substring(0, 5) : ''}`)
          }
        },
        { title: '施工负责人', align: 'center', key: 'executeUserName' },
        { title: '推荐作业人数', align: 'center', key: 'suggestMenNum' },
        { title: '状态', align: 'center', key: 'statusName' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.handleViewDetail(params.row)
                  }
                }
              }, '详情')
            ])
          }
        }

      ]
    }
  },
  computed: {
    actionDate () {
      return this.$store.state.board.actionDate
    }
  },
  created () {
    this.getAssetData()
    this.getCompanyArray()
    this.initData()
    // 获取左侧品牌作业计划数据
    this.$store.dispatch('getBrandSummaryData', { startDate: this.actionDate, endDate: this.actionDate })
    // 获取右侧统计数据
    this.$store.dispatch('getWorkTypeData', { startDate: this.actionDate, endDate: this.actionDate })
    this.$store.dispatch('getSupplierStatData', { startDate: this.actionDate, endDate: this.actionDate })
  },
  methods: {
    initData () {
      this.query.startDate = this.actionDate
      this.query.endDate = this.actionDate
      this.query.notContainDT_GXQ = this.position ? Boolean(this.position) : null
      getStationPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.tableData = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
          this.tableData.forEach(item => {
            if (this.selectedTaskIds.some(x => x === item.id)) {
              item._checked = true
            }
          })
        } else {
          this.tableData = []
          this.total = 0
          this.query.pageNumber = 1
        }
      })
    },
    getAssetData () {
      getAssetDomainList({ startDate: this.actionDate, endDate: this.actionDate }).then(res => {
        if (res && !res.errcode) {
          this.assetArray = res
        } else {
          this.assetArray = []
        }
      })
    },
    handleChangeAsset () {
      this.stationArray = this.query.assetId ? this.assetArray.find(x => x.assetId === this.query.assetId).domainList : []
    },
    changePageSize (pageSize) {
      this.query.pageSize = pageSize
      this.query.pageNumber = 1
      this.initData()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    handleViewDetail (params) {
      const obj = {
        assetId: params.assetId,
        assetName: params.assetName,
        stationName: params.stationName,
        actionDate: params.day,
        stationId: params.stationId
      }
      this.$store.commit('set_board_planDetail', obj)

      this.setLinkTagArray({ key: 'boardDetail', value: '计划站点详情' })
      this.setLinkRouterMaps(['boardDetail', () => { this.setBottomComponent('boardDetail') }])
      this.setActivedTagName('boardDetail')
    },
    selectionChange (selection) {
      this.selectedTaskIds = []
      this.selectedStationIds = []
      var tempSelectedTaskStatus = []

      // 最终选中的所有taskItem
      const curSelection = selection.map(x => {
        return { id: x.id, status: x.status, stationId: x.stationId }
      })
      this.handelPageSelections(this.tempSelectedTaskItem, curSelection, this.query.pageNumber)
      this.tempSelectedTaskItem.forEach(item => {
        this.selectedTaskIds = this.selectedTaskIds.concat(item.existSelections.map(x => x.id))
        this.selectedStationIds = this.selectedStationIds.concat(item.existSelections.map(x => x.stationId))
        tempSelectedTaskStatus = tempSelectedTaskStatus.concat(item.existSelections.map(x => x.status))
      })

      const selectedTaskStatusArray = Array.from(new Set(tempSelectedTaskStatus))
      this.selectedTaskStatus = selectedTaskStatusArray[0]

      if (selectedTaskStatusArray.length > 1) {
        this.selectedTaskIds = []
        this.selectedTaskStatus = null
        this.$Notice.warning({ desc: '请选择相同状态的任务进行批量操作' })
      }
    },
    /**
     * 发起申请
     */
    handleApply () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要对已选选项发起申请？',
        onOk: () => {
          apply({ workDayIds: JSON.stringify(this.selectedTaskIds) }).then(res => {
            if (res && res.errcode === 0) {
              this.initData()
              this.selectedTaskStatus = null
              this.selectedTaskIds = []
              this.tempSelectedTaskItem = []
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 取消申请
     */
    handleCancelApply () {
      this.handleConfirm(0, '确定要取消申请已选选项？')
    },
    /**
     * 确认申请
     */
    handleConfirmApply () {
      this.handleConfirm(2, '确定要对已选选项确认申请？')
    },
    /**
     * 延期
     */
    handleDelayConfirm () {
      this.handleConfirm(-1, '确定要延期已选选项？该操作无法回退，谨慎操作。')
    },
    handleConfirm (status, content) {
      if (!this.selectedTaskIds || this.selectedTaskIds.length === 0) {
        this.$Notice.warning({ desc: '请至少选择一条数据' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: content,
        onOk: () => {
          confirm({ workDayIds: JSON.stringify(this.selectedTaskIds), status }).then(res => {
            if (res && res.errcode === 0) {
              this.initData()
              this.selectedTaskStatus = null
              this.selectedTaskIds = []
              this.tempSelectedTaskItem = []
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },

    /**
     * 一键派单
     */
    handleQuickDispatch () {
      if (!this.selectedTaskIds || this.selectedTaskIds.length === 0) {
        this.$Notice.warning({ desc: '请至少选择一条数据' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要进行一键派单操作？',
        onOk: () => {
          assignTaskitem({ workdayIds: JSON.stringify(this.selectedTaskIds) }).then(res => {
            if (res && res.errcode === 0) {
              this.initData()
              this.selectedTaskStatus = null
              this.selectedTaskIds = []
              this.tempSelectedTaskItem = []
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    handleDownload () {
      const filename = '作业站点列表 (' + this.actionDate + ').xlsx'

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/workday/download', { startDate: this.actionDate, endDate: this.actionDate, stationIds: JSON.stringify(this.selectedStationIds) }, filename)
    }
  }
}
</script>
